@import "src/styles/index";
@import "src/components/authentication/authStyle";

.login-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 270px;
  min-width: 170px;
}
.sign-up-button{
  border: none;
  margin: 20px;
  cursor: pointer;
  outline:none;
  background:none;
}
.sign-up-button:focus{
  outline:none;
}

.login-button {
  margin: 25px auto 10px auto;
}

.forgot-password {
  margin-top: 10px;
  font-size: 12px;
  color: $primary-color;
}
