@import "src/styles/index";

.logo {
  height: 45px;
  margin-bottom: 30px;
}

.form--detail-container {
  display: flex;
  align-items: center;
  height: 37px;
  width: 100%;
  margin-top: 10px;
  background-color: $secondary-color;
  border-radius: 5px;
  overflow: hidden;

  input {
    width: calc(100% - 70px);
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.detail-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  background-color: $primary-color;

  img {
    width: 13px;
  }
}

.sign-in-content {
  font-size: 18px;
  color: #464646;
  line-height: 1.2;
  text-align: center;
}
