@import "src/styles/index";

.welcome-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  width: 100%;
  margin-top: 30px;
  padding: 30px 100px 30px 30px;
  color: #ffffff;
  background-color: $primary-color;
  border-radius: 10px;

  img {
    height: 175px;
    margin-top: -25px;
  }
}

.welcome-back-line {
  font-size: 14px;
  text-transform: uppercase;
}

.welcome-text {
  line-height: 1;
  font-size: 34px;
  font-weight: 500;
}

.dashboard-data-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3rem 0 3rem;
}

.dashboard-data-container {
  max-width: 1600px;
  width: 100%;
}

.dashboard-data-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  > div {
    flex-basis: calc(100%/4);
  }
}

.first-row-content {
  margin-left: 20px;
  text-align: center;
}

.dashboard-data-second-row {
  margin: 0 -15px;
}

.dashboard-second-row-content {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
.dashboard-data-row-item {
  display: flex;
  align-items: center;
  line-height: initial;

  > div {
    text-align: center;
  }

  img {
    height: 90px;
  }
}

.dashboard-data-title {
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  text-align: center;
  white-space: nowrap;
}

.dashboard-data-value {
  font-size: 38px;
  font-weight: 800;
  color: #003399;
}

.dashboard-graphs-container {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 5px 10%;
  width: 100%;
  margin-top: 40px;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: $primary-color;
  }

  img {
    height: 50px;
  }
}

.dashboard-total-sales-filter-container {
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    white-space: nowrap;
  }
  button.slim-button {
    background-color: $info-color;
    color: #ffffff;
  }
}

.dashboard-graph.no-data-style{
  display: flex;
  align-items: center;
  min-height: 200px;
  height: unset;
  padding: 30px;
  text-align: center;
  font-style: italic;
}

.dashboard-graph.total-sales-graph-container {
  height: 100%;
  padding-top: 5px;
}

.dashboard-graph.pipeline-graph-container {
  height: 100%;
  canvas {
    height: 100%
  }
}
