@import "src/styles/index";

.graph-container {
  width: 100%;
  //height: 100%;
  margin: 10px 0 40px 0;
  padding: 10px 30px 30px 30px;
  background-color: $secondary-variant-color;
  border-radius: 10px;
}

.filter-container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: calc(25% - 79px) calc(35% - 70px) calc(20% - 70px) calc(20% - 70px) 175px;
  gap: 20px;

  select {
    min-width: 130px;
    width: 100%;
  }
}

.filter-date-range {
  display: inline-flex;
  gap: 10px;
}

.filter-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  max-width: 100%;
  margin-left: 15px;
  padding-bottom: 12px;
}

.applied-filter-container {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  color: $placeholder-color;

  div {
    margin-right: 20px;
  }

  span {
    font-weight: 600;
    color: $secondary-font-color;
  }
}

.customer-list-heading {
  display: grid;
  grid-template-columns: 20% repeat(5, calc(13% - 15px)) calc(15% - 15px);
  column-gap: 15px;
  min-width: 768px;
  margin-top: 20px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
}

.customer-list-rows {
  display: grid;
  grid-template-columns: 20% repeat(5, calc(13% - 15px)) calc(15% - 15px);
  column-gap: 15px;
  align-items: center;
  min-width: 768px;
  margin: 10px 0;
  padding: 10px;
  font-size: 14px;
  background-color: $secondary-variant-color;
  border-radius: 5px;
}

.customer-list-rows > * {
  background-color: $secondary-variant-color;
}

.customer-name {
  display: flex;
  align-items: center;
  font-weight: 600;

  img {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
}

.table-action-field {
  display: flex;
  justify-content: flex-end;
  align-items: center;

    img {
      height: 38px;
      cursor: pointer;
  }
}

.input-range__label--max,
.input-range__label--min,{
  display: none;
}
