@import "src/styles/index";
.strategy-container{
    display: grid;
    grid-template-columns:repeat(auto-fit ,calc(50% - 15px));
    gap: 40px 20px;
    min-width: 768px;
    margin-top: 20px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
}

.strategy-title{
  padding: 0 0 10px 0;
}

.strategy-data{
  font-weight: 400;
  color: $secondary-font-color;
  font-family: 'Roboto', 'sans-serif' !important;
  position: relative;
  max-height: 63px;
  overflow: hidden;
}

.read-more{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;
  overflow: hidden;
  min-height: 63px;
}
.read-more-text{
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  padding: 0 5px;
}
.no-data-in-strategy{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  textAlign: 'center'
}
.video-script-style{
  padding-top:20px;
}
