/*Theme color*/
$primary-color: #07084B;
$primary-variant-color: #00A8FF;
$secondary-color: #ECEFF4;
$secondary-variant-color: #F9F9F9;
/*Background*/
$background-primary-color: #ffffff;
/*Event colors*/
$danger-color: #FF3233;
$info-color: #00A8FF;
$warning-color: #FECB31;
$success-color: #469A1F;
$primary-font-color: #212152;
$secondary-font-color: #464646;
$placeholder-color: #a2a2a2;
