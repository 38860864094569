@import "src/styles/index";

.DP-name-container {
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
  column-gap: 10px;
}
.container-height{
  min-height: 63px;
}

.client-name {
  text-transform: uppercase;
}

.client-other-details {
  display: grid;
  grid-template-columns: 120px calc(100% - 130px);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  margin: 20px 0 0 10px;

  .content-title {
    text-transform: uppercase;
  }
}
.upcoming-action-block-size{
  height: 100%
}
