@import "color-palette";

/* scrollbar starts here */
/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-color;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-variant-color;
}
/* scrollbar ends here */

.buttons-row {
  display: flex;
  justify-content: left;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: $secondary-font-color;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: $secondary-font-color;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  width: fit-content;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
}
.button:focus{
  outline: none;
}

.slim-button {
  min-width: fit-content;
  padding: 5px 20px;
}

.success-button {
  color: white;
  background-color: $success-color;
}

.primary-button {
  color: white;
  background-color: $primary-variant-color;
}

.danger-button {
  color: white;
  background-color: $danger-color;
}

.absolute-position-title {
  position: absolute;
  top: -16px;
  left: 10px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
}

.success-message {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1;
  background-color: $primary-color;
  border-radius: 10px;
  span:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
    color: $primary-color;
    background-color: $info-color;
    border-radius: 50%;
  }
}

.common-select {
  min-width: 150px;
  height: 30px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-font-color;
  background-color: $secondary-variant-color;
  border-radius: 5px;
  border: none;
  outline: none;
}

.common-select option {
  font-size: 14px!important;
  font-weight: 500!important;
  color: $secondary-font-color!important;
}

.common-input, .common-text-background {
  width: 100%;
  height: 30px!important;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  background-color: $secondary-variant-color;
  border-radius: 5px;
  border: none;
  outline: none;
  resize: none;
}

.common-text-background {
  color: $secondary-font-color;
}

input {
  color: $secondary-font-color;
  font-weight: 400!important;
}

.common-select-white, .common-input-white {
  width: 100%;
  background-color: white;
}

.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $secondary-font-color;
  cursor: pointer;
}

.white-input {
  background-color: white;
}

.breadcrumb {
  color: $primary-font-color;
  text-transform: uppercase;
  cursor: pointer;
}

.breadcrumb span:last-child {
  color: $placeholder-color;
  cursor: text;
}

.heading {
  font-size: 16px;
  font-weight: 700;
  color: $placeholder-color;
}

.common-title {
  font-size: 16px;
  font-weight: 800;
  color: $primary-font-color;
}
.chart-title{
  font-size: 18px;
}

.common-subtitle {
  font-size: 14px;
  font-weight: 700;
  color: $primary-font-color;
}

.common-content {
  font-size: 14px;
  font-weight: 500;
  color: $secondary-font-color;
}
.hide-detail{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 44px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-title {
  font-size: 14px;
  font-weight: 600;
  color: $primary-font-color;
}

input::placeholder {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.placeholder-color {
  color: $placeholder-color !important;
}

.font-danger {
  color: $danger-color!important;
}

/* HTML date picker changes starts here */
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
/* HTML date picker changes ends here */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed!important;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px!important;
}

.info-text-color {
  color: $info-color!important;
}

.gray-text-color {
  color: #797979 !important;
}

.d-flex {
  display: flex !important;
}

.jc-bet {
  justify-content: space-between !important;
}

.ai-center {
  align-items: center !important;
}

.ai-f-end {
  align-items: flex-end !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mar-bott-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

/* common block starts here */
.common-block {
  border-radius: 7px;

  &.red {
    background: linear-gradient(180deg, $danger-color 50%, $secondary-variant-color 50%);
  }

  &.green {
    background: linear-gradient(180deg, $success-color 50%, $secondary-variant-color 50%);
  }

  &.yellow {
    background: linear-gradient(180deg, $warning-color 50%, $secondary-variant-color 50%);
  }
  &.blue {
    background: linear-gradient(180deg, $info-color 50%, $secondary-variant-color 50%);
  }
}

.status-color {
  height: 8px;
}

.common-block--detail-container {
  height: calc(100% - 8px);
  padding: 25px 25px 40px 25px;
  background-color: $secondary-variant-color;
  border-radius: 7px;
}
/* common block ends here */

.deal-value-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
  font-size: 14px;

  * {
    white-space: nowrap;
  }
}

/* pagination changes */
.pagination {
  padding-top: 20px!important;
  font-weight: 500!important;
}

.pagination > a, .pagination > .active > a
{
  color: $primary-color !Important;
  background-color: $secondary-color !Important;
  border: none !Important;
  border-radius: 10px !important;
  outline: none!important;
}
.page-link {
  margin: 0 5px!important;
  color: $primary-font-color !Important;
  border: none!important;
  border-radius: 10px !important;
}
.page-link:hover {
  border-radius: 10px !important;
}
.page-link:focus {
  box-shadow: none!important;
}

.page-item.disabled {
  cursor: not-allowed!important;
}

.page-item.disabled .page-link {
  color: $placeholder-color!important;
  cursor: not-allowed!important;
}

.graph-legend-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  .graph {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: calc(100% - 140px);
    padding-left: 20px;

    .graph-center-text {
      position: absolute;
      padding: 10%;
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
    }
  }
}

#pipeline-chart-legends, #opportunity-chart-legends, #pipeline-reporting-legends {
  min-width: 140px;
  padding-top: 20px;
  li div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    min-width: 45px;
    margin-bottom: 5px;
    padding: 5px;
    font-weight: 600;
    color: white;
    line-height: 1;
    background-color: darkolivegreen;
    border: 2px solid white;
    border-radius: 3px;
    box-sizing: border-box;
  }
  li label {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 12px;
    color: $secondary-font-color;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}

.no-data-style {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  height: calc(100% - 100px);
  width: 100%;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: $placeholder-color;
  span {
    max-width: 300px;
    padding-top: 20px;
    text-align: center;
  }
}
