@import "src/styles/index";
@import "./node_modules/bootstrap/scss/bootstrap";

.client-detail-blocks-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  margin: 15px 0;
  gap: 20px;
}
.client-detail-page{
   min-height: 715px;
}

.upcoming-action--no-record {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: $placeholder-color;
  span {
    width: 50%;
    text-align: center;
  }
}
