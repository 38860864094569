@import "src/styles/index";

.pop-up-main-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 9999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}
.pop-up-not-chrome-container{
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 9999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}

.pop-up-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 350px;
  width: 350px;
  margin: auto;
  padding: 30px 40px 40px 40px;
  background-color: #201F5F;
  border-radius: 10px;
}

.is-blurred {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.whoops-title {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  img {
    height: 30px;
    margin-bottom: 20px
  }
  span {
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
  }
}

.whoops-content {
  margin: 20px 0 35px 0;
  font-size: 18px;
  font-weight: 300;
  color: #F9FAFF;
  text-align: center;
  line-height: initial;
}

.popup-button{
  padding: 8px 50px;
}
