@import "src/styles/index";

.filters-container {
  min-height: calc(100% - 35px);
  margin-top: 15px;
  padding: 15px;
  background-color: $secondary-variant-color;
  border-radius: 10px;
  .button {
    width: 100%;
  }
}

.filter-date {
  margin-top: 10px
}

.filter-deal-range-container {
  padding: 0 10px;
  margin-bottom: 1rem;
}

.filter-apply-filters-button {
  margin-top: 35px;
}
