@import "color-palette";

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input {
  height: 30px;
  width: inherit;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-font-color;
  border: none;
  border-radius: 5px;
  outline: none;
}

/* HTML Checkbox modification starts here */
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  line-height: 1;
}

input[type="checkbox"] + label:before {
  content: "\00a0";
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 10px 0 0;
  padding: 0;
  background-color: #cccccc;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
input[type="checkbox"]:checked + label:before {
  content: "";
  background: $primary-color;
}

input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
/* HTML Checkbox modification ends here */

.input-range__label-container {
  display: none;
}

.input-range__slider {
  background: $primary-color!important;
  border: 1px solid $primary-color!important;
}

.input-range__label--value {
  bottom: -1.7rem!important;
  top: unset!important;
}

.input-range__slider {
  height: 10px;
  width: 10px;
  margin-left: -5px;
  margin-top: -7px;
}

.input-range__track--active {
  background-color: #eeeeee;
}

@media (max-width: 1500px) {
  /* date picker changes start here */
  .react-datepicker__triangle {left: 100px!important;}

  .react-datepicker-popper {left: -80px !important}
  /* date picker changes end here */
}
