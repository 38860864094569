@import "src/styles/index";

.opportunity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: $primary-color;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 600;
    color: $primary-color;
    background-color: $warning-color;
    border-radius: 50%;
  }
}

.opportunity-container {
  display: grid;
  grid-template-columns: calc(70% - 10px) calc(30% - 10px);
  gap: 20px;
  margin-top: 20px;
}

.opportunity-detail {
  display: grid;
  grid-template-columns: calc(45% - 25px) 55%;
  gap: 20px;
}

.opportunity-other-details {
  display: grid;
  grid-template-columns: 120px calc(100% - 130px);
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
}

.opportunity--get-detail-container {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  margin-top: 40px;
}

.notes-container {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
  margin-top: 30px;
  padding: 30px 30px 10px 30px;
  background-color: $secondary-variant-color;
  border-radius: 7px;
}

.note-block {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 88px;
  height: unset;
  max-height: 180px;
//  height: 88px;
  margin-bottom: 20px;
  padding: 7px 10px;
  color: $placeholder-color;
  background-color: #ffffff;
  border-radius: 5px;
}

.edit-note-block {
  min-height: 88px;
  height: unset;
  max-height: 180px;
}

.note {
  width: calc(100% - 45px);
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-font-color;
  outline: none;
  border: none;
  resize: none;
}

.note-container {
  max-height: 140px;
  margin-bottom: 15px;
  overflow-y: auto;
}
.note-space {
  max-height: 127px;
  margin-bottom: 30px;
}
.save-note-space{
  min-height: 113px;
}

.add-note {
  margin: 0;
}

.note-time-stamp, .chat-time-stamp {
  position: absolute;
  bottom: 5px;
  left: 10px;
  font-size: 12px;
  font-weight: 400;
  color: $placeholder-color;
}

.chat-time-stamp, .right-date-time{
  color: #9595B3;
}

.note-action {
  display: flex;
  align-items: center;

  img {
    height: 16px;
    cursor: pointer;
  }

  img:last-of-type {
    height: 18px;
    margin-left: 5px;
  }
}

.opportunity-right {
  height:42vh;
}

.history-container {
  height: 350px;
}

.chat-history {
  padding: 20px 10px 20px 15px !important;

  .common-subtitle {
    margin-bottom: 5px;
  }
}

.whole-chat-container {
  height: calc(100% - 24px);
  overflow-y: auto;
}

.chat-container {
  margin-top: 10px;
  padding-right: 5px;
}

.chat-dp {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.left-conversation {
  display: flex;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
}

.right-conversation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  img {
    margin-left: 10px;
  }
}

.chat-bubble {
  position: relative;
  padding: 10px 30px 25px 10px;
  font-size: 13px;
  font-weight: 500;
  color: $placeholder-color;
  background-color: #ffffff;
  border-radius: 8px;
  flex: 1;
}

.user-bubble {
  font-weight: 400;
  color: #ffffff;
  background-color: $primary-color;
}
.btn-size{
  height: 44px;
}
.prev-next-row{
  display: flex;
  flex-direction: row;
  width:100px;
  justify-content: space-between;
  margin-top: 20px;
}
.button-disable{
  cursor: not-allowed;
  border:none !important;
}

.opportunity-error-placeholder::placeholder {
  color: $danger-color!important;
}

.opportunity-error-placeholder {
  color: $danger-color!important;
  caret-color: #444444!important;
  background-color: #fefefe!important;
}
.button-to-navigate{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
